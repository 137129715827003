<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">考试公告管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="标题" class="searchboxItem ci-full">
              <span class="itemLabel">标题:</span>
              <el-input v-model="retrievalData.noticeTitle" type="text" size="small" placeholder="请输入标题"
                clearable />
            </div>
            <div title="发布状态" class="searchboxItem ci-full">
              <span class="itemLabel">发布状态:</span>
              <el-select v-model="retrievalData.noticeState" filterable clearable size="small" placeholder="请选择发布状态">
                <el-option v-for="item in noticeStateList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button type="primary" class="bgc-bv" round @click="examinationPaperEdit()">新增</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="标题" align="left" show-overflow-tooltip prop="noticeTitle" minWidth="200" />
              <el-table-column label="发布状态" align="left" show-overflow-tooltip prop="noticeState" minWidth="100">
                <template slot-scope="scope">
                  {{ $setDictionary("EXAMINATION_NOTICE_STATE", scope.row.noticeState) }}
                </template>
              </el-table-column>
              <!-- <el-table-column label="查看人数" align="left" show-overflow-tooltip prop="lookNum" minWidth="100" >
                <template slot-scope="scope">
                  {{ scope.row.lookNum ? scope.row.lookNum : 0 }}
                </template>
              </el-table-column> -->
              <el-table-column label="操作" align="center" width="200px">
                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="examinationPaperEdit(scope.row)">编辑</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="examinationPaperRelease(scope.row)" >{{ (scope.row.noticeState == '10'||scope.row.noticeState == '30') ? '发布':'回收' }}</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="examinationPaperDelete(scope.row)">删除</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "examination_onlineTest_announcementOfManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        noticeTitle: "", // 标题
        noticeState: "", // 公告状态
      },
      // 发布状态 - 下拉数据
      noticeStateList: [],
    };
  },
  created() {
    this.dataDictionary();
  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 数据字典
    dataDictionary() {
      // 发布状态
      const noticeStateList = this.$setDictionary(
        "EXAMINATION_NOTICE_STATE",
        "list"
      );
      const list1 = [];
      for (const key in noticeStateList) {
        list1.push({
          value: key,
          label: noticeStateList[key],
        });
      }
      this.noticeStateList = list1;
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.retrievalData.noticeTitle) {
        params.noticeTitle = this.retrievalData.noticeTitle;
      }
      if (this.retrievalData.noticeState) {
        params.noticeState = this.retrievalData.noticeState;
      }
      this.doFetch({
        url: "/biz/examination/notice/pageList",
        params,
        pageNum,
      },true,6);
    },
    // 操作 - 新增&编辑
    examinationPaperEdit(row) {
      this.$router.push({
        path: "/web/examination/onlineTest/announcementOfManagementAddEdit",
        query: {
          noticeId: row?.noticeId ?? ''
        }
      });
    },
    // 操作 - 发布&回收
    examinationPaperRelease(row) {
      this.$post('/biz/examination/notice/updateState', { noticeId: row.noticeId }, 3000, true, 6)
        .then((res) => {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.getData(this.pageNum)
        })
        .catch((err) => {
          return;
        });
    },
    // 操作 - 删除
    examinationPaperDelete(row){
      this.$post('/biz/examination/notice/delete', { noticeId: row.noticeId }, 3000, true, 6)
        .then((res) => {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.getData(this.pageNum)
        })
        .catch((err) => {
          return;
        });
    }
  },
};
</script>
<style lang="less" scoped></style>
  